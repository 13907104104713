.TimeDial {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
    align-items: center;
}

.Seperator {
    font-size: 38px;
    font-weight: bold;
    color: #4A4A4A;
}