.Scanned {
    width: 100%;
    
}


.Header {
    width: 50%;
}

.FahrtenList {
        width: 100%;
        background-color: #4A4A4A1A;
    
}



@media (max-width: 700px) {
    .Header {
        width: 90%;
    }
}