.NoItems {
    font-size: 25px;
    color: #4a4a4a;
    margin-top: 100px;
}


@media(max-width: 700px){
    .VisibleItem {
        display: block;
    }
    
    .InVisibleItem {
        display: none;
    }
}