.SearchButton {
    background-color: #4A4A4A;
    color: white;
    font-size: 20px;
    border: none;
    padding: 10px 80px;
    margin-top: 80px;
    margin-bottom: 20px
}

.ChangeButton {
    background-color: #4A4A4A;
    color: white;
    font-size: 9px;
    border: none;
    padding: 5px 10px;
}

.SearchButton:disabled {
    opacity: 0.5;
}


@media(max-width: 700px){
    .SearchButton {
        padding: 8px 40px;
        margin-bottom: 30px;
        margin-top: 30px;
    }
}