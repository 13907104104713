.TimeSelector {
    display: flex;
    align-items: center;
    margin-top: 40px;
}

.TimeSelector p {
    font-size: 30px;
    color: #4A4A4A;
}

.TimeDial {
    margin-left: auto;
}


@media(max-width: 700px){
    .TimeSelector p {
        font-size: 15px;
        font-weight: 500;
    }
}