.FahrtenListHeader {

}

.FahrtenListHeader_scanned ul{
    display: flex;
    flex-direction: row;
    list-style: none;
    text-transform: uppercase;
}

.FahrtenListHeader_scanned ul li {
    margin: 0px 10px;
}

.FahrtenListHeader ul {
    
    display: flex;
    flex-direction: row;
    list-style: none;
    text-transform: uppercase;
}


.FahrtenListHeader ul li {
    margin: 0px 10px;
}


.ListItemRight {
    margin-left: auto !important;
    margin-right: 70px !important;
    padding-right: 12px;
}


.EndHeader {
    margin-left: 45px !important;
}

.LastItem {
    margin-right: 70px !important;
}


@media (max-width: 700px) {

    .FahrtenListHeader ul{
        padding: 0px;
    }
    .FahrtenListHeader ul li {
        font-size: 10px;
        font-weight: bold;
        color: #4A4A4A;
    }

    .FahrtenListHeader_scanned ul {
        background-color: #ffffff;
        padding: 0px;
        margin-top: 0px;
        padding-bottom: 10px;
    }
    .FahrtenListHeader_scanned ul li {
        font-size: 10px;
        font-weight: bold;
        color: #4A4A4A;
    }
}





@media(max-width: 700px){
    .LastItem {
       margin-right: 25px !important;

    }

    .ListItemRight {
        margin-right: 25px !important;
    }
}