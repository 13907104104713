.Infobox {
    display: flex;
    margin: 10px auto;
    width: 75%;
    align-items: center;
    justify-content: space-between;
    
}

.StartContainer {
    text-align: left;
}

.Infobox h3 {
    font-size: 27px;
    font-weight: 500;
    color: #4A4A4A;
    margin: 0px;
    margin-top: 20px;
}



@media (max-width: 900px) {
    .Infobox {
        width: 100%;
    }
    .Infobox h3 {
        font-size: 20px;
    }
}