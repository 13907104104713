.SettingsHeader {
    border-bottom: 2px solid #4A4A4A;
    text-align: left;
    margin-right: 170px;
    padding-left: 140px;
    padding-bottom: 40px;
    padding-top: 42px
}

.SettingsHeader h1 {
    margin: 0px;
    font-size: 69px;
    font-weight: bold;
    color: #4A4A4A;
}

@media(max-width: 700px){
    .SettingsHeader {
        margin-left: 0px;
        margin-right: 50px;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 30px;
    }

    .SettingsHeader h1 {
        font-size: 20px;
        
    }
}