.Settings {
    width: 100%;
    height: 100%;
    
}

.Settings_oetz {
    width: 90%;
    margin: 0px auto;
    height: 100%;
}


@media(min-width: 1200px){
    .Settings {
        width: 90%;
        margin: 0px auto;
    }
}