.FahrtenList {
    margin: 150px 100px 50px 100px;
    padding-bottom: 10px;
}

.FahrtenList_dark {
    background-color: #4A4A4A1A;
    padding: 20px 200px;
    padding-bottom: 10px;
}

.ScannedMobile {
    margin: 10px 10px;
}

.EmptyHeading {
    color: #4A4A4A;
    font-weight: 400;
    margin-top: 200px;
}


@media (min-width: 700px) and (max-width: 1600px) {
    .FahrtenList {
        margin: 20px 10px;
    }

    .FahrtenList_dark {
        padding: 20px 20px;
        padding-bottom: 10px;
    }
}

@media (max-width: 700px){
    .FahrtenList {
        margin: 20px 10px;
    }

    .FahrtenList_dark {
        padding: 0px 0px;
        padding-bottom: 10px;
    }
}