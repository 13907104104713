.Container {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;

    
}



@media(max-width: 900px){
    .Container {
        display: block;
        height: auto;
    }
}