.scroll-select-container {
    background-color: #4A4A4A1A;
    width: 80px;
}

.scroll-selector-area {
    border-top: none;
    border-bottom: none;
}

.scroll-item{
    font-size: 21px !important;
    opacity: 0.5 !important;
}

.selected-time {
    font-size: 38px !important;
    opacity: 1 !important;
}

.selector-caret-bottom {
    pointer-events: none;
    margin-top: -15px;
    
    background-image:url("../../../../assets/arrow_down.svg");
    background-repeat:no-repeat;
    background-size: 20px 30px;
    background-position: center 90%;
    min-height: 20px;
}

.selector-caret-top {
    pointer-events: none;
    margin-bottom: -15px;
    
    background-image:url("../../../../assets/arrow_down.svg");
    background-repeat:no-repeat;
    background-size: 20px 30px;
    background-position: center 90%;
    min-height: 20px;
    transform: rotate(180deg)
}

.scroll-select-list {
    overflow-x: hidden;
}


@media(max-width: 700px){
    .scroll-item {
        font-size: 19px !important;
    }
    .selected-time {
        font-size: 33px !important;
    }
}