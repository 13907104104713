.Dropdown {
    width: 100%;
    margin-bottom: 20px;
}

.Dropdown h3 {
    font-size: 30px;
    color: #4A4A4A;
    font-weight: 500;
    margin: 0px;
    margin-bottom: 5px;
    text-align: left;
}

.Dropdown select {
    appearance:none;
    background:url("../../assets/arrow_down.svg");
    background-repeat:no-repeat;
	background-size: 20px 30px;
	background-position: 95% 10px;
    width: 100%;
    background-color: #4A4A4A1A;
    font-size: 25px;
    font-style: italic;
    color: #4A4A4A;
    padding: 10px;
    border: none;
    
}

@media(max-width: 700px){

    .Dropdown {
        margin-bottom: 40px;
    }
    .Dropdown h3 {
        font-size: 15px;
        font-weight: 400px;
    }

    .Dropdown select {
        font-size: 15px;
        background-size: 15px 15px;
    }
}