.FahrtenListItem {
    background-color: white;
    margin: 10px 0px;
}


.Upper {
    padding: 3px;
}


.Upper ul {
   display: flex;
   flex-direction: row;
   list-style: none;
}

.Upper ul li {
    font-size: 15px;
    min-width: 40px;
}

.Lower p{
    font-style: italic;
    color: #4a4a4a;
    margin: 0px;
    text-align: left;
    padding-left: 40px;
    padding-bottom: 10px;
    font-size: 10px;
}


.ItemRight {
    margin-left: auto;
    margin-right: 110px;
}

.ItemRightEnd {
    margin-right: 80px;
}

.IdItem {
    margin-right: 50px;
}


.Icon {
    
}

.Green {
    font-weight: bold;
    color: #86CE60;
}

.Green img, .Yellow img, .Red img {
    margin-left: 10px;
    height: 14px;
}

.Yellow {
    font-weight: bold;
    color: #FFC05C;
}

.Red {
    font-weight: bold;
    color: #E24A4A;
}



@media (max-width: 700px){
    .ItemRight {
        margin-right: 40px;
        margin-left: auto;
    }
    .ItemRightEnd {
        margin-right: 20px;
    }
    .Upper ul {
        padding: 0px;
    }
    .IdItem {
        margin-right: 15px;
    }
}
