.Form {
    width: 100%;
    min-width: 300px;
    margin-top: 40px;
}


@media (min-width: 700px) and (max-width: 1500px){
    .Form {
        
    }
}

@media(max-width: 700px) {
    .Form {
        width: 100%;
    }
}